import * as React from 'react'
import cn from 'classnames'

import { IconButton } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'

import { Portal } from '@toasttab/ec-layout'
import { SpaDate } from '@local/shared-services'

import { DueDate } from '@local/payroll/shared/components/dueDate'
import { useScreenSize } from '@toasttab/use-screen-size'
import { usePageHeader, PayrollStep } from './usePageHeader'
import { PageHeaderStepper as Stepper } from './stepper'
import { usePayPeriod } from '../../hooks/usePayPeriod'

interface PageHeaderProps {
  currentStep?: PayrollStep
  back?: string
}

interface PageHeaderProps {
  currentStep?: PayrollStep
  back?: string
}

const HeaderInfo = ({ back }: { back?: string }) => {
  const { payPeriod } = usePayPeriod()

  if (!payPeriod) return null

  const { name, status, startDate, endDate, dueDate, postDeadlineText } =
    payPeriod

  return (
    <div
      className={cn('flex w-1/2 md:w-auto', {
        // Can remove this when back link is no longer part of the designs (e.g. uses navbar instead)
        'xxl:-ml-12': !!back
      })}
    >
      {back === undefined ? null : (
        <IconButton
          as='a'
          testId='back-chevron'
          href={back}
          icon={<ChevronLeftIcon aria-label='chevron left icon' size='md' />}
        />
      )}
      <div className='flex flex-col justify-start'>
        <div
          className='mb-1 text-secondary type-overline'
          data-testid='payroll-date-range'
        >
          {SpaDate.formatDateRange(startDate, endDate, 'LLL dd yyyy')}
        </div>
        <div className='flex flex-row'>
          <div
            className='text-default type-large font-bold mr-2.5'
            data-testid='payroll-name'
          >
            {name}
          </div>
          <span data-testId='due-date'>
            <DueDate
              displayType='badge'
              postDeadline={postDeadlineText}
              deadlineUtc={dueDate}
              status={status}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

const PageHeaderContent = ({
  children,
  currentStep,
  back
}: PageHeaderProps & { children?: React.ReactNode }) => {
  const { steps } = usePageHeader({ currentStep })
  const hasChildren = React.Children.count(children) > 0
  const screenSize = useScreenSize()

  return (
    <div
      className={cn(
        'flex flex-col space-y-3 max-w-screen-xl mx-auto py-6 px-4 mb-6',
        'xl:px-0'
      )}
    >
      <div className='flex flex-row justify-between space-x-8'>
        <HeaderInfo back={back} />
        {hasChildren && <div>{children}</div>}
      </div>
      <Stepper steps={steps} screenSize={screenSize} />
    </div>
  )
}

export const PageHeader = (
  props: PageHeaderProps & { children?: React.ReactNode }
) => {
  return (
    <Portal id='page-header' component={<PageHeaderContent {...props} />} />
  )
}
