import { generatePath } from 'react-router-dom'

// See comment in routes file for explanation of this clunky URL
export const listPathBase = `/:client/payroll/payrolls/list/`

export const listPath = ({ client }: { client: string }) =>
  generatePath(listPathBase, { client })

// NOTE: see notes in PayrollRouteParams as to why we use payroll_id instead of payrollUuid
export const previewPathBase = `/:client/payroll/:payroll_id/paycycle/preview/`

export const previewPath = ({
  client,
  uuid,
  secureId
}: {
  client: string
  uuid: string
  secureId: string
}) =>
  `${generatePath(previewPathBase, {
    client,
    payroll_id: uuid
  })}?id=${secureId}`

export const employeesPathBase = `/:client/payroll/:payroll_id/paycycle/employees/`

export const employeesPath = ({
  client,
  uuid,
  secureId
}: {
  client: string
  uuid: string
  secureId: string
}) =>
  `${generatePath(employeesPathBase, {
    client,
    payroll_id: uuid
  })}?id=${secureId}`

export const reviewPathBase = `/:client/payroll/:payroll_id/paycycle/review/`

export const reviewPath = ({
  client,
  uuid,
  secureId
}: {
  client: string
  uuid: string
  secureId: string
}) =>
  `${generatePath(reviewPathBase, {
    client,
    payroll_id: uuid
  })}?id=${secureId}`

/*
  Import pages MUST use secureID because they actually embed pieces of esx-web-rendered pages within themselves.
  As a result, we have to keep the URLs untouched and in the shape that esx-web expects until refactored within web.
  However, the pages *do* also load some ec-payroll-spa content, and so the UUID is also required.
  What results is an inversion of most other pages for the IDs in URL param vs query param.
  See usePayrollParams for the logic that displays how we handle this inversion.

  For the same reason, make sure that navigation to these import pages triggers a full page redirect (window.location.href = ),
  rather than a clientside-only redirect (useNavigate).
*/
export const importTimesheetsPathBase = `/mvc/:client/Company/Payroll/TimeImport/:secureId`

export const importTimesheetsPath = ({
  client,
  uuid,
  secureId,
  step
}: {
  client: string
  uuid: string
  secureId: string
  step?: string
}) =>
  `${generatePath(importTimesheetsPathBase, {
    client,
    secureId
  })}?id=${uuid}${step ? `&=${step}` : ''}`

export const importTimesheetsAlohaPathBase = `/mvc/:client/Company/Payroll/AlohaLaborReport/:secureId`

export const importTimesheetsAlohaPath = ({
  client,
  uuid,
  secureId,
  step
}: {
  client: string
  uuid: string
  secureId: string
  step?: string
}) =>
  `${generatePath(importTimesheetsAlohaPathBase, {
    client,
    secureId
  })}?id=${uuid}${step ? `&=${step}` : ''}`

// Below are legacy pages, not actually hosted by SPA
// included here to make it easier to track if this URL changes in future
// Like the importTimesheet pages, make sure these trigger a full page redirect.
export const timesheetsLegacyPath = ({
  client,
  uuid
}: {
  client: string
  uuid?: string
}) =>
  `/mvc/${client}/Management/TimeAndLabor/Timesheets${
    uuid ? `?payrollUuid=${uuid}` : ''
  }`

// Web supports a redirect on /:client/payroll/:uuid/taxes/import as well,
// but we don't need that -- can just go there directly
export const importTaxesLegacyPath = ({
  client,
  secureId
}: {
  client: string
  secureId: string
}) => `/mvc/${client}/Company/Payroll/TaxesImport/${secureId}`
