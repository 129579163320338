import * as React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import {
  LazyListAchCts,
  LazyGenerateACH,
  LazyPayrollList,
  LazyEmployeeComparison,
  LazyForm8655List,
  LazyForm8655,
  LazyForm8655Reports,
  LazyPreflight
} from '../lazy-pages'
import {
  AlohaTimeImportHeader,
  CustomTimeImportHeader
} from '../experience/details/timeImport'

import { useNavigationV3 } from '../experience/list/hooks/useNavigationV3'
import { PageTitle } from '../shared/components/layout'
import { useCurrentUser } from '@local/ec-app'
import { useTranslation } from 'react-i18next'
import { Portal } from '@toasttab/ec-layout'
import { LazyEmployees, LazyReviewPage } from '../experience/details/lazy-pages'
import { PayPeriodProvider } from './PayPeriodProvider'
import {
  employeesPathBase,
  previewPathBase,
  reviewPathBase,
  importTimesheetsAlohaPathBase,
  importTimesheetsPathBase,
  listPathBase,
  listPath
} from './pathHelper'

// Need to use arrays instead of components because React Router (incorrectly IMO)
// treats any non-Route children of Routes as improper, even if they just render a Route
const form8655Routes = [
  <Route
    // keys are arbitrary (React yells at you if you don't have them)
    // but here they also represent aspirational route path changes,
    // i.e. what would update to once old routes can be discarded
    key='/form8655'
    path='/:client/payroll/forms/8655'
    element={<LazyForm8655List />}
  />,
  <Route
    key='/form8655/:id'
    path='/:client/payroll/forms/8655/:feinId'
    element={<LazyForm8655 />}
  />,
  <Route
    key='/form8655/:id/download'
    path='/mvc/:client/Company/Reports/YearEnd'
    element={<LazyForm8655Reports />}
  />
]

const payrollRoutes = [
  <Route
    key='/payroll/:id/paycycle/preflight'
    path={previewPathBase}
    element={<LazyPreflight />}
  />,
  <Route
    key='/payroll/:id/paycycle/employees'
    path={employeesPathBase}
    element={<LazyEmployees />}
  />,
  <Route
    key='/payroll/:id/paycycle/review'
    path={reviewPathBase}
    element={<LazyReviewPage />}
  />,
  <Route
    key='/timeimport/:id'
    path={importTimesheetsPathBase}
    element={<Portal id='page-header' component={<CustomTimeImportHeader />} />}
  />,
  <Route
    key='/timeimport/:id/aloha'
    path={importTimesheetsAlohaPathBase}
    element={<Portal id='page-header' component={<AlohaTimeImportHeader />} />}
  />
]

const PayrollRoutes = () => {
  const { t } = useTranslation('payroll')
  const { client } = useCurrentUser()
  const shouldShowNavHeadings = useNavigationV3()

  return (
    <BrowserRouter>
      <Routes>
        {form8655Routes}
        <Route element={<PayPeriodProvider />}>{payrollRoutes}</Route>

        <Route
          path='/mvc/:client/admin/payrollops/index'
          element={<LazyListAchCts />}
        />
        <Route
          path='/mvc/:client/admin/payrollops/achDashboard'
          element={<LazyGenerateACH />}
        />
        <Route path={listPathBase} element={<LazyPayrollList />} />
        {/*
          This route does nothing -- currently the BFF has no handling for this route,
          forwards to web, which then explicitly redirects on this route to the longer URL
          listed above.

          Eventually, we should just add a generic catchall for `/:client/payroll/*` to the BFF and
          banquet-root, and then just let the SPA do all the handling for URLs that it likes. Then
          we can delete this old route from both the SPA and esx-web.
        */}
        <Route path='/:client/payroll/list/' element={<LazyPayrollList />} />

        <Route
          path='/:client/payroll/details/employeecomparisonreport/:secureId/*'
          element={<LazyEmployeeComparison />}
        />
        <Route
          path='/Customer/ManagePayrollToDos/Index.aspx'
          element={
            shouldShowNavHeadings ? (
              <PageTitle
                title={t('payrolls.title.payrollReminders')}
                subTitle={t('payrolls.header.title')}
                backHref={`/${client}/payroll/Payrolls/List`}
              />
            ) : null
          }
        />
        <Route
          path='/mvc/:client/Management/AssetsAndExpenses/Expense'
          element={
            shouldShowNavHeadings ? (
              <PageTitle
                title={t('payrolls.title.expenses')}
                subTitle={t('payrolls.header.title')}
                backHref={`/${client}/payroll/Payrolls/List`}
              />
            ) : null
          }
        />
        <Route
          path='/mvc/:client/Company/Payroll/QuickCalcs'
          element={
            shouldShowNavHeadings ? (
              <PageTitle
                title={t('payrolls.title.payrollManualChecks')}
                titleEducationId='manual-checks-title'
                subTitle={t('payrolls.header.title')}
                backHref={`/${client}/payroll/Payrolls/List`}
              />
            ) : null
          }
        />
        {/*
          TODO These two routes are legacy holdovers from when we had to support secureID in the URL.
          There are redirects on more sensible URLs in esx-web (see PayrollAreaRegistration there),
          which then forward to these URLs below so that UUIDs can be converted to secureID.
          We don't need that behavior anymore, but just in case anybody is still using those URLs,
          redirect them back to the List page (best we can do without UUIDs in the URLs themselves.)
          With time, will be safer to delete these routes entirely (and from esx-web as well.)
        */}
        <Route
          path='/:client/payroll/employees/spreadsheet/:secureId'
          element={<Navigate to={listPath({ client })} />}
        />
        <Route
          path='/:client/payroll/Details/Review/:secureId'
          element={<Navigate to={listPath({ client })} />}
        />

        {/*
          This is useless, since all routes have to be whitelisted in BFF+web first anyways,
          and will hit the 404 from esx-web before making it here.
          Just in case, redirect to List page.
        */}
        <Route
          path='*'
          element={<Navigate to={`/${client}/payroll/payrolls/list`} />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default PayrollRoutes
